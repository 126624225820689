import { render, staticRenderFns } from "./Eventos.vue?vue&type=template&id=1f537468&scoped=true"
import script from "./Eventos.vue?vue&type=script&lang=js"
export * from "./Eventos.vue?vue&type=script&lang=js"
import style0 from "./Eventos.vue?vue&type=style&index=0&id=1f537468&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f537468",
  null
  
)

export default component.exports