<template>
  <div class="container">
    <BaseHeader title="Eventos" :navigation="navigation"></BaseHeader>
    <div class="box-total" v-if="!loading">
      <div class="total-vendas info">
        <!-- <b-form-checkbox
            v-model="status"
            @change="changeStatusForm"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ status ? " Ativos " : "Inativos" }}
            </p>
          </b-form-checkbox> -->
      </div>
      <div>
        <p class="total-vendas">
          Total de eventos:
          <span>{{ total }}</span>
        </p>
      </div>
      <div></div>
    </div>

    <div v-else>
      <b-skeleton
        width="100%"
        height="83px"
        style="border-radius: 10px"
      ></b-skeleton>
    </div>
    <div class="header-table" style="justify-content: end; gap: 15px">
      <BaseButton variant="info" @click="redirectAgendamentos" v-if="!loading">
        Ver agendamentos
      </BaseButton>
      <b-skeleton
        v-else
        width="135px"
        height="50px"
        type="button"
        style="border-radius: 10px"
      ></b-skeleton>
    </div>
    <!-- BODY -->
    <template v-if="!loading && events">
      <b-tabs
        class="mt-4"
        nav-class="TabHeader no-border padding"
        active-nav-item-class="TabHeader--active"
        :no-nav-style="true"
      >
        <b-tab :title="`Próximos`" active @click="typeList = 'Próximos'">
          <div class="days" v-if="!loading_list">
            <div
              v-for="(event, date) in events"
              :key="date"
              class="filter-list-days"
            >
              <span class="list-days"
                >{{
                  event[0].start_time | moment("dddd, D [de] MMMM [de] YYYY")
                }}
                <span v-if="validDate(event[0].start_time)">
                  |
                  <span class="day_type">{{
                    filterDate(event[0].start_time)
                  }}</span>
                </span>
              </span>
              <div class="eventos-conteudo-container">
                <div
                  v-for="(event2, index) in event"
                  :key="index"
                  class="eventos-conteudo"
                  :class="dynamicColorCalendar(event2) + '_color'"
                >
                  <div class="display-flex2">
                    <h1
                      class="eventos-conteudo-titulo"
                      @click="openModal('event-vizualize-calendar', event2)"
                    >
                      {{ filterName(event2) }}
                    </h1>
                    <div id="cardFunil" v-if="!isMobile">
                      <b-dropdown id="dropPage" right style="margin-top: 12px">
                        <template #button-content>
                          <img src="@/assets/img/icons/dots.svg" alt="dots" />
                        </template>
                        <b-dropdown-item
                          @click="openModal('event-vizualize-calendar', event2)"
                          >Detalhes</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="time">
                    <span>Período</span>
                    <h1>{{ event2.duration }} min</h1>
                  </div>
                  <div class="time">
                    <span>Horário</span>
                    <h1>
                      {{ event2.start_time | moment("HH:mm") }} -
                      {{ event2.end_time | moment("HH:mm") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NOT EVENT -->
          <b-row
            v-if="!loading_list && !Object.keys(events).length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum evento pelos próximos dias.</p>
          </b-row>
          <!-- SE EXISTIR LOADING-->
          <div class="d-flex justify-content-center" v-if="loading_list">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
        <b-tab :title="`Pendentes`" @click="typeList = 'Pendentes'">
          <div class="days" v-if="!loading_list">
            <div
              v-for="(event, date) in events"
              :key="date"
              class="filter-list-days"
            >
              <span class="list-days"
                >{{
                  event[0].start_time | moment("dddd, D [de] MMMM [de] YYYY")
                }}
                <span v-if="validDate(event[0].start_time)">
                  |
                  <span class="day_type">{{
                    filterDate(event[0].start_time)
                  }}</span>
                </span>
              </span>
              <div class="eventos-conteudo-container">
                <div
                  v-for="(event2, index) in event"
                  :key="index"
                  class="eventos-conteudo"
                  :class="dynamicColorCalendar(event2) + '_color'"
                >
                  <div class="display-flex2">
                    <h1
                      class="eventos-conteudo-titulo"
                      @click="openModal('event-vizualize-calendar', event2)"
                    >
                      {{ filterName(event2) }}
                    </h1>
                    <div id="cardFunil" v-if="!isMobile">
                      <b-dropdown id="dropPage" right style="margin-top: 12px">
                        <template #button-content>
                          <img src="@/assets/img/icons/dots.svg" alt="dots" />
                        </template>
                        <b-dropdown-item
                          @click="openModal('event-vizualize-calendar', event2)"
                          >Detalhes</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="time">
                    <span>Período</span>
                    <h1>{{ event2.duration }} min</h1>
                  </div>
                  <div class="time">
                    <span>Horário</span>
                    <h1>
                      {{ event2.start_time | moment("HH:mm") }} -
                      {{ event2.end_time | moment("HH:mm") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NOT EVENT -->
          <b-row
            v-if="!loading_list && !Object.keys(events).length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum evento pendente.</p>
          </b-row>
          <!-- SE EXISTIR LOADING-->
          <div class="d-flex justify-content-center" v-if="loading_list">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
        <b-tab :title="`Anteriores`" @click="typeList = 'Anteriores'">
          <div class="days" v-if="!loading_list">
            <div
              v-for="(event, date) in events"
              :key="date"
              class="filter-list-days"
            >
              <span class="list-days"
                >{{
                  event[0].start_time | moment("dddd, D [de] MMMM [de] YYYY")
                }}
                <span v-if="validDate(event[0].start_time)">
                  |
                  <span class="day_type">{{
                    filterDate(event[0].start_time)
                  }}</span>
                </span>
              </span>
              <div class="eventos-conteudo-container">
                <div
                  v-for="(event2, index) in event"
                  :key="index"
                  class="eventos-conteudo"
                  :class="dynamicColorCalendar(event2) + '_color'"
                >
                  <div class="display-flex2">
                    <h1
                      class="eventos-conteudo-titulo"
                      @click="openModal('event-vizualize-calendar', event2)"
                    >
                      {{ filterName(event2) }}
                    </h1>
                    <div id="cardFunil" v-if="!isMobile">
                      <b-dropdown id="dropPage" right style="margin-top: 12px">
                        <template #button-content>
                          <img src="@/assets/img/icons/dots.svg" alt="dots" />
                        </template>
                        <b-dropdown-item
                          @click="openModal('event-vizualize-calendar', event2)"
                          >Detalhes</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="time">
                    <span>Período</span>
                    <h1>{{ event2.duration }} min</h1>
                  </div>
                  <div class="time">
                    <span>Horário</span>
                    <h1>
                      {{ event2.start_time | moment("HH:mm") }} -
                      {{ event2.end_time | moment("HH:mm") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NOT EVENT -->
          <b-row
            v-if="!loading_list && !Object.keys(events).length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum evento anterior.</p>
          </b-row>
          <!-- SE EXISTIR LOADING-->
          <div class="d-flex justify-content-center" v-if="loading_list">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
        <b-tab :title="`Concluídos`" @click="typeList = 'Concluídos'">
          <div class="days" v-if="!loading_list">
            <div
              v-for="(event, date) in events"
              :key="date"
              class="filter-list-days"
            >
              <span class="list-days"
                >{{
                  event[0].start_time | moment("dddd, D [de] MMMM [de] YYYY")
                }}
                <span v-if="validDate(event[0].start_time)">
                  |
                  <span class="day_type">{{
                    filterDate(event[0].start_time)
                  }}</span>
                </span>
              </span>
              <div class="eventos-conteudo-container">
                <div
                  v-for="(event2, index) in event"
                  :key="index"
                  class="eventos-conteudo"
                  :class="dynamicColorCalendar(event2) + '_color'"
                >
                  <div class="display-flex2">
                    <h1
                      class="eventos-conteudo-titulo"
                      @click="openModal('event-vizualize-calendar', event2)"
                    >
                      {{ filterName(event2) }}
                    </h1>
                    <div id="cardFunil" v-if="!isMobile">
                      <b-dropdown id="dropPage" right style="margin-top: 12px">
                        <template #button-content>
                          <img src="@/assets/img/icons/dots.svg" alt="dots" />
                        </template>
                        <b-dropdown-item
                          @click="openModal('event-vizualize-calendar', event2)"
                          >Detalhes</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="time">
                    <span>Período</span>
                    <h1>{{ event2.duration }} min</h1>
                  </div>
                  <div class="time">
                    <span>Horário</span>
                    <h1>
                      {{ event2.start_time | moment("HH:mm") }} -
                      {{ event2.end_time | moment("HH:mm") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NOT EVENT -->
          <b-row
            v-if="!loading_list && !Object.keys(events).length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum evento concluido.</p>
          </b-row>
          <!-- SE EXISTIR LOADING-->
          <div class="d-flex justify-content-center" v-if="loading_list">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
        <b-tab :title="`Inativados`" @click="typeList = 'Inativados'">
          <div class="days" v-if="!loading_list">
            <div
              v-for="(event, date) in events"
              :key="date"
              class="filter-list-days"
            >
              <span class="list-days"
                >{{
                  event[0].start_time | moment("dddd, D [de] MMMM [de] YYYY")
                }}
                <span v-if="validDate(event[0].start_time)">
                  |
                  <span class="day_type">{{
                    filterDate(event[0].start_time)
                  }}</span>
                </span>
              </span>
              <div class="eventos-conteudo-container">
                <div
                  v-for="(event2, index) in event"
                  :key="index"
                  class="eventos-conteudo"
                  :class="dynamicColorCalendar(event2) + '_color'"
                >
                  <div class="display-flex2">
                    <h1
                      class="eventos-conteudo-titulo"
                      @click="openModal('event-vizualize-calendar', event2)"
                    >
                      {{ filterName(event2) }}
                    </h1>
                    <div id="cardFunil" v-if="!isMobile">
                      <b-dropdown id="dropPage" right style="margin-top: 12px">
                        <template #button-content>
                          <img src="@/assets/img/icons/dots.svg" alt="dots" />
                        </template>
                        <b-dropdown-item
                          @click="openModal('event-vizualize-calendar', event2)"
                          >Detalhes</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="time">
                    <span>Período</span>
                    <h1>{{ event2.duration }} min</h1>
                  </div>
                  <div class="time">
                    <span>Horário</span>
                    <h1>
                      {{ event2.start_time | moment("HH:mm") }} -
                      {{ event2.end_time | moment("HH:mm") }}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NOT EVENT -->
          <b-row
            v-if="!loading_list && !Object.keys(events).length"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">Nenhum evento concluido.</p>
          </b-row>
          <!-- SE EXISTIR LOADING-->
          <div class="d-flex justify-content-center" v-if="loading_list">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
      </b-tabs>
    </template>
    <!-- BODY LOADING SKELETON -->
    <div v-if="loading" class="indicador d-flex grid gap-3 row">
      <div class="p-2" v-for="(item, index) in pagination.perPage" :key="index">
        <b-skeleton height="217px" width="370px"></b-skeleton>
        <div style="display: flex; gap: 140px">
          <b-skeleton width="130px" class="mt-4"></b-skeleton>
          <b-skeleton width="60px" class="mt-4"></b-skeleton>
        </div>
        <b-skeleton width="60px" class="mt-4"></b-skeleton>
      </div>
    </div>
    <!-- PAGINATE -->
    <b-row>
      <b-col>
        <Paginate
          v-if="events"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :disabled="true"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>
    <!-- SEARCH NULL -->
    <b-row v-if="!loading && !events" class="Table-body justify-content-center">
      <p class="nao-encontrado">Nenhum evento foi encontrado</p>
    </b-row>
    <!-- MODAL -->
    <ConectGoogle />
    <EventVizualize
      :selectedEvent="selectedEvent"
      :calendars="calendarsEvents"
    />
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import Paginate from "@/components/Paginate";
//
import BaseHeader from "@/components/BaseHeader";
import ConectGoogle from "@/components/Agendamentos/ConectGoogle.vue";
//
import CalendarsService from "@/services/resources/CalendarService";
const serviceCalendar = CalendarsService.build();
//
import AppointmentsService from "@/services/resources/AppointmentsService";
const serviceAppointments = AppointmentsService.build();
//
import GoogleService from "@/services/resources/GoogleService";
const serviceGoogle = GoogleService.build();
//
import EventVizualize from "@/components/AgendamentosEventos/EventVizualizeModal.vue";
export default {
  components: { BaseHeader, Paginate, ConectGoogle, EventVizualize },
  data() {
    return {
      calendarsEvents: [],
      typeList: "Próximos",
      navigation: [
        { link: "Aplicativos", to: "/dynamicRoute/apps" },
        { link: "Eventos", to: this.$route.fullPath },
      ],
      selectedEvent: {},
      total: 0,
      events: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      loading: false,
      loading_list: false,
      client: {
        width: 0,
      },
    };
  },
  watch: {
    typeList() {
      this.getAllEvents(this.calendarsEvents, this.typeList);
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    dynamicColorCalendar(event) {
      var meta_color = event.calendar.metas.find(
        (item) => item.meta_key === "color"
      );
      if (meta_color) {
        var parse_obj = JSON.parse(meta_color.meta_value);
        if (parse_obj && parse_obj.color === "#2133D2") {
          return `blue`;
        } else if (parse_obj && parse_obj.color === "#FF0C37") {
          return `red`;
        } else if (parse_obj && parse_obj.color === "#FFCB37") {
          return `yellow`;
        } else if (parse_obj && parse_obj.color === "#2ED7EE") {
          return `blue_light`;
        } else if (parse_obj && parse_obj.color === "#5D21D2") {
          return `purple`;
        } else if (parse_obj && parse_obj.color === "#17191F") {
          return `dark`;
        } else if (parse_obj && parse_obj.color === "#3483FA") {
          return `blue_dark`;
        } else if (parse_obj && parse_obj.color === "#00AF7B") {
          return `greenn_dark`;
        } else if (parse_obj && parse_obj.color === "#00E4A0") {
          return `greenn_light`;
        } else {
          return `blue`;
        }
      }
    },
    filterName(event) {
      if (event && event.leads && event.leads[0]) {
        return event.leads[0].name;
      }
      return "Sem lead";
    },
    filterDate(date) {
      const data = new Date(date);
      const dataAtual = new Date();
      const dataFormatada = data.toISOString().split("T")[0];

      if (dataFormatada === dataAtual.toISOString().split("T")[0]) {
        return "Hoje";
      } else {
        const dataAmanha = new Date(dataAtual);
        dataAmanha.setDate(dataAtual.getDate() + 1);

        if (dataFormatada === dataAmanha.toISOString().split("T")[0]) {
          return "Amanhã";
        } else {
          return data.toLocaleDateString();
        }
      }
    },
    validDate(date) {
      const data = new Date(date);
      const dataAtual = new Date();
      const dataFormatada = data.toISOString().split("T")[0];
      return (
        dataFormatada === dataAtual.toISOString().split("T")[0] ||
        dataFormatada === this.getTomorrowDate()
      );
    },
    getTomorrowDate() {
      const dataAtual = new Date();
      const dataAmanha = new Date(dataAtual);
      dataAmanha.setDate(dataAtual.getDate() + 1);
      return dataAmanha.toISOString().split("T")[0];
    },
    redirectAgendamentos() {
      this.$router.push({ name: "Agendamentos" });
    },
    editCalendar(id) {
      this.$router.push({ name: "AgendamentosEditar", params: { id: id } });
    },
    getMetas(metas, meta, parse) {
      var meta_find = metas.find((item) => item.meta_key == meta);
      if (meta_find) {
        if (parse) {
          return JSON.parse(meta_find.meta_value);
        } else {
          return meta_find.meta_value;
        }
      } else {
        return "Meta não encontrada!";
      }
    },
    conectGoogle() {
      serviceGoogle
        .read({ id: "client" })
        .then((resp) => {
          if (resp && resp[0] && resp[0] == "cliente não encontrado") {
            this.$bvModal.show("integration-google");

            return;
          }
          this.$bvModal.show("create-new-event");
          this.$router.push({ name: "AgendamentosCriar" });
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    deleteCalendar(event) {
      Vue.swal({
        title: "Evento",
        text: `Deseja realmente deletar o calendário?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          var data = {
            id: event.id,
          };
          serviceCalendar
            .destroy(data)
            .then(() => {
              this.loading = false;
              this.$grToast.toast("Evento deletado com sucesso", {
                title: "Evento",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.getEvents();
            })
            .catch(() => {
              this.$grToast.toast("Erro ao deletar evento", {
                title: "Evento",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.getEvents(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.getEvents(this.search);
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.getEvents(this.search);
    }, 500),
    getAllEvents(resp, typeList) {
      this.loading_list = false;
      this.events = [];

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Zerar horas, minutos, segundos e milissegundos para comparar apenas as datas

      // Criar um conjunto para armazenar datas únicas
      const uniqueDates = new Set();

      const eventsByDate = {};

      for (let x = 0; x < resp.length; x++) {
        const array_events = resp[x];
        const eventDate = new Date(array_events.start_time);
        // Zerar horas, minutos, segundos e milissegundos para comparar apenas as datas -->
        eventDate.setHours(0, 0, 0, 0);
        const dataFormatada = eventDate.toISOString().split("T")[0];
        // Filtrar os events com base no tipo -->
        if (
          (typeList === "Próximos" &&
            eventDate >= currentDate &&
            array_events.status !== "done" &&
            array_events.status !== "inactive" &&
            array_events.status !== "lead_inactive") ||
          (typeList === "Anteriores" && eventDate < currentDate) ||
          (typeList === "Concluídos" && array_events.status === "done") ||
          (typeList === "Pendentes" &&
            array_events.status === "active" &&
            array_events.status !== "done") ||
          (typeList === "Inativados" &&
            (array_events.status === "inactive" ||
              array_events.status === "lead_inactive"))
        ) {
          uniqueDates.add(dataFormatada);

          if (!eventsByDate[dataFormatada]) {
            eventsByDate[dataFormatada] = [];
          }

          eventsByDate[dataFormatada].push(array_events);
        }
      }
      // Ordena as datas -->
      const sortedDates = Array.from(uniqueDates).sort();
      this.events = sortedDates.reduce((result, date) => {
        result[date] = eventsByDate[date];
        return result;
      }, {});
      this.loading_list = false;
    },
    getEvents(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;
      this.total = 0;

      var data = {
        id: "/list",
        page: this.pagination.currentPage,
      };

      if (this.search) {
        data.search = this.search;
      }

      serviceAppointments
        .read(data)
        .then((resp) => {
          this.calendarsEvents = resp.data;
          this.total = resp.total;
          this.getAllEvents(resp.data, this.typeList);
        })
        .catch((error) => {
          // console.log("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal(data, event) {
      this.$bvModal.show(data);
      this.selectedEvent = event;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.getEvents();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
.blue_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #2133d2;
}
.red_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ff0c37;
}
.yellow_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #ffcb37;
}
.blue_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #2ed7ee;
}
.blue_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #5d21d2;
}
.dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #17191f;
}
.blue_dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #3483fa;
}
.greenn_dark_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #00af7b;
}
.greenn_light_color::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background: #00e4a0;
}
.filter-list-days {
  display: grid;
  gap: 20px;
}
.days {
  display: grid;
  gap: 20px;

  .list-days {
    margin-left: 5px;
    .day_type {
      color: var(--bluee);
      font-weight: 600;
    }
  }
}
.time-content {
  display: flex;
  gap: 10px;
  align-items: center;
}
.icon {
  width: 20px;
  height: 20px;
}
.time {
  span {
    color: var(--gray03);
  }
  h1 {
    margin: 0;
    font-weight: 600;
    color: var(--gray01);
    font-size: 14px;
  }
}
.paginas-menu {
  border-radius: 10px;
  border: none;
}

.margin {
  height: 60px !important;
}

input {
  margin-bottom: 0 !important;
}

.eventos-conteudo-opt {
  color:  var(--greenn);
}

.eventos-conteudo-titulo {
  cursor: pointer;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
}

.eventos-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.eventos-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
  display: grid;
  gap: 10px;
  padding: 20px;
  position: relative;
}

.img-conteudo {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}

.img-icon {
  width: 12px;
  height: 13.5px;
}

.display-flex2 {
  display: flex;
  justify-content: space-between;
}

.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

//
.pd {
  padding-right: 0;
  padding-left: 0;
}

.id {
  padding-left: 22px;
}

@media (max-width: 768px) {
  .input-busca {
    position: relative !important;
  }

  .eventos-conteudo-container {
    grid-template-columns: 1fr;
  }

  .col-1 {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .col-2 {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .col-3 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.break {
  word-break: break-all;
}

.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}

.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}

.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}

.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  padding-top: 15px;
}

.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}

.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}

.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  position: absolute;
  top: 0px;
  left: 0;
}

.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 20px;
  top: 17px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

@media screen and (max-width: 1300px) {
  .search {
    left: 23px;
  }
}

.container-pesquisa {
  text-align: right;
  z-index: 1;
}

.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .Table-header {
    gap: 50px;
    text-align: center;
  }

  .Table-body {
    gap: 50px;
  }

  .header-table {
    display: block;
    padding: 0;
    position: relative;
  }

  .flex-sale {
    display: block;
  }

  .flex-sale div {
    margin-left: 0;
  }

  .flex-acoes {
    margin: 15px 0;
  }

  .container-pesquisa {
    text-align: left;
    padding-bottom: 10px;
  }

  .search {
    left: 20px;
  }

  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}

.dados-cliente div {
  margin-bottom: 3px;
}

.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}

.whats-cliente svg {
  margin-right: 10px;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.Table-body {
  padding: 15px;
}

.Table-body::before {
  left: 0;
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}

.title-report-margin-top {
  margin-top: 41px;
}

.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}
</style>
