import Rest from "@/services/Rest";

/**
 * @typedef {AppointmentsService}
 */
export default class AppointmentsService extends Rest {
  /**
   * @type {String}
   */
  static resource = "appointments";
}
