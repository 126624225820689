import Rest from "@/services/Rest";

/**
 * @typedef {CalendarService}
 */
export default class CalendarService extends Rest {
  /**
   * @type {String}
   */
  static resource = "calendars";
}
